<template>
    <div class="card card-default" :class="loading ? 'whirl' : ''">
        <div class="card-header bg-gray-lighter text-bold">{{$t('common.view.settings.profile.heading')}}</div>
        <div class="card-body">

            <b-form @submit.prevent="updateProfile()">
                <b-form-group
                        id="username"
                        :label="$t('common.view.settings.profile.field.username.title')"
                        label-for="username-input" >
                    <b-form-input id="username-input" v-model="$store.getters.user('name')" readonly></b-form-input>
                </b-form-group>

                <b-form-group
                        id="description"
                        :label="$t('common.view.settings.profile.field.description.title') + ':'"
                        label-for="description-input">
                    <b-form-textarea
                            id="description-input"
                            :placeholder="$t('common.view.settings.profile.field.description.placeholder')"
                            v-model="social.profile_description" >

                    </b-form-textarea>
                </b-form-group>
                <b-form-group
                        id="FBname"
                        :label="$t('common.view.settings.profile.field.facebook.title') + ':'"
                        label-for="FBname-input"  >
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text class="brand-url pl-2 pr-2"><i id="facebook-logo" class="fab fa-facebook fa-lg mr-2"></i>facebook.com/</b-input-group-text>
                        </template>
                        <b-form-input id="FBname-input" v-model="social.profile_facebook"></b-form-input>
                    </b-input-group>
                </b-form-group>


                <b-form-group
                        id="IGname"
                        :label="$t('common.view.settings.profile.field.instagram.title') + ':'"
                        label-for="IGname-input" >
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text class="brand-url pl-2 pr-2"><i id="instagram-logo" class="fab fa-instagram fa-lg mr-2"></i>instagram.com/</b-input-group-text>
                        </template>
                        <b-form-input id="IGname-input" v-model="social.profile_instagram"></b-form-input>
                    </b-input-group>
                </b-form-group>


                <b-form-group
                        id="LIname"
                        :label="$t('common.view.settings.profile.field.linkedin.title') + ':'"
                        label-for="LIname-input" >
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text class="brand-url pl-2 pr-2"><i id=linkedin-logo class="fab fa-linkedin fa-lg mr-2"></i>linkedin.com/in/</b-input-group-text>
                        </template>
                        <b-form-input id="LIname-input" v-model="social.profile_linkedin"></b-form-input>
                    </b-input-group>
                </b-form-group>


                <b-form-group
                        id="TWITTERname"
                        :label="$t('common.view.settings.profile.field.twitter.title') + ':'"
                        label-for="TWITTERname-input"
                >
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text class="brand-url pl-2 pr-2"><i id="twitter-logo" class="fab fa-twitter fa-lg mr-2"></i>twitter.com/</b-input-group-text>
                        </template>
                        <b-form-input id="TWITTERname-input" v-model="social.profile_twitter"></b-form-input>
                    </b-input-group>
                </b-form-group>


                <b-form-group
                        id="SKYPEname"
                        :label="$t('common.view.settings.profile.field.skype.title') + ':'"
                        label-for="SKYPEname-input"
                >
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text class="pl-2 pr-2"><i id="skype-logo" class="fab fa-skype fa-lg"></i></b-input-group-text>
                        </template>
                        <b-form-input id="SKYPEname-input" v-model="social.profile_skype"></b-form-input>
                    </b-input-group>
                </b-form-group>


                <b-form-group
                        id="TGname"
                        :label="$t('common.view.settings.profile.field.telegram.title') + ':'"
                        label-for="TGname-input"
                >
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text class="pl-2 pr-2"><i id="telegram-logo" class="fab fa-telegram fa-lg"></i></b-input-group-text>
                        </template>
                        <b-form-input id="TGname-input" v-model="social.profile_telegram"></b-form-input>
                    </b-input-group>
                </b-form-group>


                <b-form-group
                        id="WAname"
                        :label="$t('common.view.settings.profile.field.whatsapp.title') + ':'"
                        label-for="WAname-input"
                >
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text class="pl-2 pr-2"><i id="whatsapp-logo" class="fab fa-whatsapp fa-lg"></i></b-input-group-text>
                        </template>
                        <b-form-input id="WAname-input" v-model="social.profile_whatsapp"></b-form-input>
                    </b-input-group>
                </b-form-group>


                <label>{{ $t('common.view.settings.profile.privacy') }}</label>
                <b-list-group>
                    <b-list-group-item>

                        <label class="switch d-inline mr-2">
                            <input type="checkbox" checked="checked" v-model="social.privacy_show_plant_count"/>
                            <span></span>
                        </label>

                        <span>{{ $t('common.view.settings.profile.switch.show-plants') }}</span>
                    </b-list-group-item>

                    <b-list-group-item>

                        <label class="switch d-inline mr-2">
                            <input type="checkbox" checked="checked" v-model="social.privacy_show_team_size"/>
                            <span></span>
                        </label>

                        <span>{{ $t('common.view.settings.profile.switch.show-team-size') }}</span>
                    </b-list-group-item>

                    <b-list-group-item>

                        <label class="switch d-inline mr-2">
                            <input type="checkbox" checked="checked" v-model="social.privacy_show_real_name"/>
                            <span></span>
                        </label>

                        <span>{{ $t('common.view.settings.profile.switch.show-real-name') }}</span>
                    </b-list-group-item>
                </b-list-group>

                <b-button class="mt-3" type="submit" variant="primary">{{ $t('common.button.save.label') }}</b-button>
            </b-form>

        </div>
    </div>
</template>

<style>
    .brand-url {
        width: 10.2em;
    }

    #facebook-logo {
        color: #3b5998;
    }

    #instagram-logo {
        color: black;
    }

    #linkedin-logo {
        color: #2867b2;
    }

    #twitter-logo {
        color: #00acee;
    }

    #skype-logo {
        color: #00aff0;
    }

    #telegram-logo {
        color: #0088cc;
    }

    #whatsapp-logo {
        color: #25d366;
    }

</style>

<script>

    export default {
        components: {},
        data() {
            return {
                social: {
                    privacy_show_plant_count: 0,
                    privacy_show_team_size: 0,
                    privacy_show_real_name: 0,
                    profile_description: "",
                    profile_facebook: "",
                    profile_instagram: "",
                    profile_linkedin: "",
                    profile_twitter: "",
                    profile_skype: "",
                    profile_telegram: "",
                    profile_whatsapp: ""
                },
                loading: true,
            }
        },
        created() {
            this.loadProfile();
        },
        methods: {
            updateProfile() {
                this.loading = true;
                this.$api.update('social/profile', this.social).then((response) => {
                    this.social = response.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    this.$swal.fire('Validation error', error.data.message, 'error');
                })
            },
            loadProfile() {
                this.loading = true;
                this.$api.get('social/profile').then((response) => {
                    this.social = response.data;
                    this.loading = false;
                });
            }
        }
    }
</script>